<template>
  <v-row class="my-5">
    <div class="main-bg-img"></div>
    <v-col cols="12" sm="10" md="8" lg="7" class="mx-auto">      
      <h1 class="mb-2">
        <v-icon>mdi-basket</v-icon> Votre panier 
        <template v-if="$store.state.basket.products.length > 0">
          ({{ $store.state.basket.products.length }})
        </template>
        <template v-else>
          est vide
        </template>
      </h1>

      <template v-if="$store.state.basket.products.length">

        <!-- LISTE DES ARTICLES -->
        <v-card>
          <v-list three-line style="border-radius:4px;">
            <template v-for="(product, p) in $store.state.basket.products">
              <v-list-item :key="p" outlined class="my-3">
              
                <v-img :max-width="100" :aspect-ratio="1/1" class="mr-3 img-rounded" :src="baseUrl() + product.image"></v-img>
              
                <v-list-item-content>
                  
                  <v-list-item-title>{{ product.name }}</v-list-item-title>

                  <v-list-item-subtitle class="mb-1" v-if="product.repro">
                  <b class="green--text">
                    {{ reproPrice(product.repro) }}€ X {{ product.repro.quantity }} 
                    exemplaire<template v-if="product.repro.quantity > 1">s</template>
                  </b>
                  <br><small>{{ reproSizeStr(product.repro) }}</small>
                  
                  </v-list-item-subtitle>

                  <v-list-item-subtitle class="d-none d-sm-inline">{{ product.description.substr(0, 60) + "..." }}</v-list-item-subtitle>

                  <template v-if="product.reproductible">
                    <v-btn v-if="product.repro != null" small dark elevation="0" color="teal" class="mr-2 mb-2">
                      <v-icon small>mdi-content-copy</v-icon> 
                      <v-icon small>mdi-check</v-icon> Repro<span class="d-none d-sm-inline">duction</span>
                    </v-btn>
                  </template>

                  <v-btn small elevation="0" class=" mb-2" @click="$store.dispatch('basket/removeProduct', product._id)">
                    Annuler <span class="d-none d-sm-inline">cet article</span>
                  </v-btn>

                </v-list-item-content>

                <v-list-item-action>
                  <v-chip outlined color="green" class="font-large"
                          v-if="product.repro == null">
                    {{ product.price }}€
                  </v-chip>
                  <v-chip outlined color="green" class="font-large"
                          v-else>
                    {{ reproPrice(product.repro) * product.repro.quantity }}€
                  </v-chip>
                </v-list-item-action>
                
              </v-list-item>
            </template>
          </v-list>
        </v-card>

        <!-- MESSAGE -->
        <v-card class="my-3 pa-5 text-left">
          <v-row>
            <v-col cols="12" md="7" lg="8">
              <p class="grey--text text--darken-1" style="font-size:14px;">
                <v-icon small color="grey darken-1" class="pr-1">mdi-alert</v-icon>
                <b>Important :</b> Pour les mandala bois et les toiles, les frais de port sont fixés à 20€ pour la France métropolitaine, 
                et des frais supplémentaires peuvent s'appliquer en fonction de la destination et de la taille des produits commandés (> 100 cm)
                <br><br>
                Les frais de port des reproductions papier sont offerts.
                <br><br>
                Pour toutes demandes concernant les modalités de paiement et de livraison, veuillez me contacter en cliquant ce lien : 

                <router-link to="/contact">Contact</router-link>
              </p>
            </v-col>

            <v-col cols="12" md="5" lg="4" class="text-right">
              Total des articles : <b>{{ totalBasket }} €</b><br>
              Frais de port : <b>{{ fraisDePort }} €</b><br>
              <v-divider class="my-2"/>
              Total : <b class="ml-2">{{ totalBasket + fraisDePort }} €</b><br>
              <v-icon x-large style="padding:50px 100px;" class="mx-auto d-none d-md-inline-block">mdi-basket</v-icon>
            </v-col>

          </v-row>

        </v-card>

        <!-- RECAPITULATIF MONTANTS -->
        <!-- <v-card class="my-3 pa-5 text-right">
          Total des articles : <b>{{ totalBasket }} €</b><br>
          Frais de port : <b>{{ fraisDePort }} €</b>
        </v-card> -->

        <!-- FORMULAIRE -->
        <h1 class="mt-5"><v-icon>mdi-information</v-icon> Info livraison</h1>
        <v-card class="my-3 pa-3">
          <v-col cols="12">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field :hide-details="formErrors['name'] == null" 
                              :error-messages="formErrors['name']"
                              label="Nom / Prénom" outlined v-model="resa.name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field :hide-details="formErrors['email'] == null" 
                              :error-messages="formErrors['email']"
                              label="Adresse e-mail" outlined v-model="resa.email"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field :hide-details="formErrors['streetAddress'] == null" 
                              :error-messages="formErrors['streetAddress']"
                              placeholder="Adresse, n° rue, etc..." 
                              label="Adresse de livraison" outlined v-model="resa.streetAddress">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="resa.postalCode"
                              :hide-details="formErrors['postalCode'] == null" 
                              :error-messages="formErrors['postalCode']"
                              label="Code postal" outlined ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field :hide-details="formErrors['city'] == null" 
                              :error-messages="formErrors['city']"
                              label="Ville" outlined v-model="resa.city"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete :hide-details="formErrors['country'] == null" :items="countries" 
                                item-text="name" :error-messages="formErrors['country']"
                                label="Pays" outlined v-model="resa.country"></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-textarea hide-details label="Quelque chose à rajouter ?" outlined v-model="resa.comment"></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      
      <v-card>
      <v-alert color="red" outlined v-if="resa.country != 'France'">
        Pour les livraisons hors de France métropolitaine, les frais de port sont calculés au cas par cas.
        Vous recevrez un e-mail très prochainement vous indiquant le montant total de la livraison, ainsi qu'un lien pour effectuer le paiement avant expédition.
      </v-alert>
      </v-card>
      
      <v-toolbar class="mb-3">
        <v-spacer/>
        <v-checkbox class=" mt-5 mr-2" style="padding-top:2px;" v-model="checkCGV" label="J'ai pris connaissance des"></v-checkbox>
        <router-link target="_blank" to="/conditions">
          <u>condition générales de ventes</u>
        </router-link> 
      </v-toolbar>
      
      <v-toolbar>
        <v-spacer/>
        Total du panier : <b class="ml-2">{{ totalBasket + fraisDePort }} €</b>
        <!-- <v-btn color="green" outlined class="ml-3">Enregistrer ma commande</v-btn>  -->
        <v-btn color="green" elevation="0" class="ml-3" dark @click="validateCommande()">
          <v-icon class="mr-2">mdi-credit-card-fast</v-icon>
          <template v-if="resa.country == 'France'">Valider et payer</template>
          <template v-else>Valider ma réservation</template>
        </v-btn> 
      </v-toolbar>
      <!-- <small>Vous recevrez un e-mail de confirmation dès que votre commande sera </small> -->
      

      </template>
    </v-col>
  </v-row>
</template>

<script>
import core from '../../plugins/core.js'
import countries from '../../plugins/countries.json'
import axios from "axios"
import router from "@/router/router" 

export default {
  name: "basket",
  components: {
  },
  data: function () {
      return {
          products: [],
          productToShow: false,
          showProductDialog: false,
          countries: [],
          //fraisDePort: 20,

          repro: false,
          support: 'carte',
          size: 1,

          formErrors: [],
          checkCGV: true,

          resa: {
            products: [],
            name: '',
            email: '',
            streetAddress: '',
            country: null,
            postalCode: '',
            city: '',
            comment: ''
          }
      };
  },
  mounted: function(){
    this.countries = countries
    this.resa.country = this.countries[0].name
    this.repro = this.$route.params.repro == "true" ? true : false 
    this.support = this.$route.params.support != null ? this.$route.params.support : 'carte'
    this.size = this.$route.params.size != null ? parseInt(this.$route.params.size) : 1
  },
  methods: {
      async validateCommande(){
        if(!this.checkCGV) return 

        this.resa.products = []
        this.$store.state.basket.products.forEach((prod)=>{
          this.resa.products.push({ id : prod._id, repro: prod.repro })
        })
        console.log("this.resa", this.resa.products)
        console.dir(this.resa)
        
        const { data } = await axios.post('/reservation/create', this.resa)
        if(data.error == false){
          this.$store.dispatch('app/openDialogMailDev', data.emailParams)
          if(this.resa.country == 'France') this.startCBPayment(data.resa._id)
          else                              router.push("/payment/reservation/thanks/"+data.resa._id)
        }else{
          this.formErrors = []
          data.validateErrors.details.forEach((error)=>{
            console.log("error.message", error.message)
            this.formErrors[error.path[0]] = error.message
            console.log("this.formErrors", this.formErrors)
          })
        }
      },
      async startCBPayment(resaId){
        console.log('/pay/reservation/all' + resaId)
        const { data } = await axios.post('/pay/reservation/all/' + resaId)

        if(data.error == false){
          console.log('Success after /pay/reservation/' + resaId, data)
          window.location.href = data.sessionUrl
        }else{
          console.log('Error after /pay/reservation/' + resaId)
          //this.formError = true
        }

      },

      reproPrice(repro){ //retourne le prix d'une reproduction en fonction du support et de la taille choisie
        let price = 0
        this.$store.state.basket.reproConfig[repro.support].forEach(reproConf => {
          if(reproConf.sizeNum == repro.sizeNum) price = reproConf.price
        });
        console.log("basket price", price)
        return price
      },
      reproSizeStr(repro){ //retourne le prix d'une reproduction en fonction du support et de la taille choisie
        let size = ""
        this.$store.state.basket.reproConfig[repro.support].forEach(reproConf => {
          if(reproConf.sizeNum == repro.sizeNum) size = reproConf.sizeStr
        });
        console.log("basket size", size)
        return size
      },

      baseUrl(){ return core.baseUrl() }
  },
  computed:{
    totalBasket(){
      let total = 0
      this.$store.state.basket.products.forEach((prod)=>{
        if(prod.repro == null) total += prod.price
        else total += this.reproPrice(prod.repro) * prod.repro.quantity
      })
      return total
    },
    fraisDePort(){
      let fp = 0
      let cats = []
      //parcours la liste des produits de la commande
      this.$store.state.basket.products.forEach((prod) => {
        if(prod.repro == null){
          //ajoute les frais de port de chaque catégorie (une seule fois par catégorie)
          if(cats.indexOf(prod.categories[0]._id) == -1){
            fp += parseFloat(prod.categories[0].fraisDePort)
            cats.push(prod.categories[0]._id)
          }
        }
      })
      return fp
    },
  }
};
</script>
